.bp3-dialog {
  background: #ccc;
}

.data-caption {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.data-caption > p {
  margin-right: 5px;
}

.data-caption-800 {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  width: 800px;
}

.data-dropzone {
  background: white;
  border-radius: 4px;
  padding: 4px;
  width: 100%;
  text-align: center;
  /* margin-bottom: 10px; */
}
