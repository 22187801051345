// Copyright 2017 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "~@blueprintjs/core/src/common/variables";

$select-popover-max-height: $pt-grid-size * 30 !default;
$select-popover-max-width: $pt-grid-size * 40 !default;

.#{$ns}-select-popover {
  .#{$ns}-popover-content {
    // use padding on container rather than margin on input group
    // because top margin leaves some empty space with no background color.
    padding: $pt-grid-size / 2;
  }

  .#{$ns}-input-group {
    margin-bottom: 0;
  }

  .#{$ns}-menu {
    max-height: $select-popover-max-height;
    max-width: $select-popover-max-width;
    overflow: auto;
    padding: 0;

    &:not(:first-child) {
      // adjust padding to account for that on .#{$ns}-popover-content above
      padding-top: $pt-grid-size / 2;
    }
  }
}
